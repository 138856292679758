"use strict";

var _interopRequireDefault = require("D:/www/erp/back-end/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.getInfo = getInfo;
exports.getList = getList;
exports.getPage = getPage;
exports.remove = remove;
exports.update = update;
exports.updateStatus = updateStatus;
var _request = _interopRequireDefault(require("@/utils/request"));
function getPage() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/core/dashboard/page',
    method: 'get',
    params: params
  });
}
function getList() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/core/dashboard/list',
    method: 'get',
    params: params
  });
}
function getInfo(id) {
  return (0, _request.default)({
    url: '/core/dashboard/info',
    method: 'get',
    params: {
      id: id
    }
  });
}
function create(data) {
  return (0, _request.default)({
    url: '/core/dashboard/create',
    method: 'post',
    data: data
  });
}
function update(data) {
  return (0, _request.default)({
    url: '/core/dashboard/update',
    method: 'post',
    data: data
  });
}
function updateStatus(data) {
  return (0, _request.default)({
    url: '/core/dashboard/updateStatus',
    method: 'post',
    data: data
  });
}
function remove(data) {
  return (0, _request.default)({
    url: '/core/dashboard/remove',
    method: 'post',
    data: data
  });
}