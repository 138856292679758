"use strict";

var _interopRequireDefault = require("D:/www/erp/back-end/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Navbar", {
  enumerable: true,
  get: function get() {
    return _index2.default;
  }
});
Object.defineProperty(exports, "Setting", {
  enumerable: true,
  get: function get() {
    return _index.default;
  }
});
Object.defineProperty(exports, "Sidebar", {
  enumerable: true,
  get: function get() {
    return _index3.default;
  }
});
Object.defineProperty(exports, "TagsView", {
  enumerable: true,
  get: function get() {
    return _index4.default;
  }
});
var _index = _interopRequireDefault(require("./Setting/index.vue"));
var _index2 = _interopRequireDefault(require("./Navbar/index.vue"));
var _index3 = _interopRequireDefault(require("./Sidebar/index.vue"));
var _index4 = _interopRequireDefault(require("./TagsView/index.vue"));