"use strict";

var _interopRequireDefault = require("D:/www/erp/back-end/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/www/erp/back-end/base/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _path = _interopRequireDefault(require("path"));
var _validate = require("@/utils/validate");
var _Item = _interopRequireDefault(require("./Item"));
var _Link = _interopRequireDefault(require("./Link"));
var _FixiOSBug = _interopRequireDefault(require("./FixiOSBug"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default = {
  name: 'MenuItem',
  components: {
    Item: _Item.default,
    AppLink: _Link.default
  },
  mixins: [_FixiOSBug.default],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    collapse: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['config'])), {}, {
    textColor: function textColor() {
      return this.config.text_color;
    },
    backgroundColor: function backgroundColor() {
      return this.config.background_color;
    }
  }),
  data: function data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    return {};
  },
  methods: {
    hasOneShowingChild: function hasOneShowingChild() {
      var _this = this;
      var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var parent = arguments.length > 1 ? arguments[1] : undefined;
      var showingChildren = children.filter(function (item) {
        if (!item.navbar || item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this.onlyOneChild = item;
          return true;
        }
      });
      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        var obj = JSON.parse(JSON.stringify(parent));
        delete obj.children;
        this.onlyOneChild = obj;
        // Colber 调整菜单(:to="onlyOneChild.path" 少了path.resolve()调用)
        // this.onlyOneChild = { ... parent, path: '', noShowingChildren: true }
        return true;
      }
      return false;
    },
    resolvePath: function resolvePath(routePath) {
      if ((0, _validate.isExternal)(routePath)) {
        return routePath;
      }
      if ((0, _validate.isExternal)(this.basePath)) {
        return this.basePath;
      }
      return _path.default.resolve(this.basePath, routePath);
    }
  }
};
exports.default = _default;